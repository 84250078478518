




























































































import { Vue, Component, Prop } from "vue-property-decorator";
import { utils } from "@/utils/okta-utils";
import Catch from "@/shared/decorators/catch-errors";
import options from "@/shared/constants/toast-options";
import { paymentActivityService } from "@/services/payments/payment-activity.service";
import { PaymentActivityRecord } from "@/models/payments/payment-activity/payment-activity-record";
import { TableColumnSelector } from "@/models/payments/table-column-selector";
import { PaymentInvoiceDetail } from "@/models/payments/payment-activity/payment-invoice-detail";
import { formatMonetaryValue } from "@/helpers/decimal-formatting-helper";

@Component
export default class PaymentDetails extends Vue {
  @Prop() payment!: PaymentActivityRecord;

  private isLoading = false;
  private invoiceList: PaymentInvoiceDetail[] = [];
  private invoiceListHeaders: TableColumnSelector[] = [
    {
      align: "start",
      text: "Invoice Date",
      sortable: false,
      value: "document_date",
      selected: false
    },
    {
      align: "start",
      text: "Invoice ID",
      sortable: false,
      value: "document_reference",
      selected: false
    },
    {
      align: "end",
      text: "Payment Amount",
      sortable: false,
      value: "total_payment_amount",
      selected: false
    }
  ];

  private setLocalDateTime(utcDate: string) {
    return utcDate ? utils.utcToLocalDateString(new Date(utcDate)) : "";
  }

  private setDateWithoutTime(utcDate: string) {
    return utcDate ? utils.formatStringToDateWithFormat(utcDate, "L") : "";
  }

  private formatMonetaryVal(value: number | string) {
    return formatMonetaryValue(value);
  }

  @Catch((error: any, context: any) => {
    context.isLoading = false;
    context.$toasted.show(`<p>Error occurred</p>`, {
      ...options.ERROR_OPTIONS
    });
  })
  async created() {
    this.isLoading = true;
    this.invoiceList = await paymentActivityService.getDetails(
      this.payment.payment_uuid
    );
    this.isLoading = false;
  }
}
