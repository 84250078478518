import { app } from "@/main";
import { PaymentFilterMenuSelection } from "@/models/payments/payment-filter-menu-selection";
import { PaymentsServiceInterface } from "@/models/payments/payments-service.interface";
import { PaymentActivityFiltersResponse } from "@/models/payments/payment-activity/payment-activity-filters-response";
import { PaymentActivityResponse } from "@/models/payments/payment-activity/payment-activity-response";
import { PaymentInvoiceDetail } from "@/models/payments/payment-activity/payment-invoice-detail";

class PaymentActivityService implements PaymentsServiceInterface {
  private baseURL = "api/payments/payments";

  private buildQueryParameters(
    filters: PaymentFilterMenuSelection,
    page?: number,
    pageSize?: number
  ): string {
    let query = `?begin_date=${filters.startDate}&end_date=${filters.endDate}`;
    if (filters.firstItemSelection != "") {
      // eslint-disable-next-line
      query += `&company_id=${encodeURIComponent(filters.firstItemSelection)}`;
    }
    if (filters.secondItemSelection != "") {
      // eslint-disable-next-line
      query += `&payment_type=${encodeURIComponent(filters.secondItemSelection)}`;
    }
    if (filters.thirdItemSelection != "") {
      // eslint-disable-next-line
      query += `&payment_status=${encodeURIComponent(filters.thirdItemSelection)}`;
    }
    if (page) {
      query += `&page=${page}`;
    }
    query += pageSize ? `&page_size=${pageSize}` : "&page_size=10";
    return query;
  }

  public async get(
    filters: PaymentFilterMenuSelection,
    page = 1,
    pageSize?: number
  ): Promise<PaymentActivityResponse> {
    const query = this.buildQueryParameters(filters, page, pageSize);
    const apiResponse = await app.$ravenapi.get(this.baseURL.concat(query));
    return apiResponse?.data;
  }

  public async getFilters(): Promise<PaymentActivityFiltersResponse> {
    const apiResponse = await app.$ravenapi.get(
      this.baseURL.concat("/filters")
    );
    return apiResponse?.data;
  }

  public async getDetails(uuid: string): Promise<PaymentInvoiceDetail[]> {
    const apiResponse = await app.$ravenapi.get(
      this.baseURL.concat("/invoices").concat(`?payment_uuid=${uuid}`)
    );
    return apiResponse?.data?.data ?? [];
  }
}

export const paymentActivityService = new PaymentActivityService();
