















































































































































import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { cloneDeep } from "lodash";
import { utils } from "@/utils/okta-utils";
import Catch from "@/shared/decorators/catch-errors";
import options from "@/shared/constants/toast-options";
import TableColumnSelectorModal from "@/components/payments/TableColumnSelectorModal.vue";
import PaymentsFilterMenu from "@/components/payments/PaymentsFilterMenu.vue";
import IconButton from "@/components/design-system/buttons/IconButton.vue";
import IconTextButton from "@/components/design-system/buttons/IconTextButton.vue";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import ExportResultsModal from "@/components/payments/ExportResultsModal.vue";
import PaymentDetails from "@/components/payments/PaymentDetails.vue";
import PaymentActivityStore from "@/store/payments/payment-activity.store";
import UserStore from "@/store/user.store";
import { downloadCsvFile } from "@/helpers/csv-file-helper";
import { paymentActivityService } from "@/services/payments/payment-activity.service";
import { TableColumnSelector } from "@/models/payments/table-column-selector";
import { PaymentActivityRecord } from "@/models/payments/payment-activity/payment-activity-record";
import { InputObject } from "@/models/document-entry/input-object";
import { PaymentFilterMenuSelection } from "@/models/payments/payment-filter-menu-selection";
import { PaymentActivityFiltersResponse } from "@/models/payments/payment-activity/payment-activity-filters-response";
import { PaymentPaginationMetadata } from "@/models/payments/payment-pagination-metadata";
import { PaymentActivityResponse } from "@/models/payments/payment-activity/payment-activity-response";
import { formatMonetaryValue } from "@/helpers/decimal-formatting-helper";

@Component({
  components: {
    "icon-button": IconButton,
    "icon-text-button": IconTextButton,
    "table-column-selector-modal": TableColumnSelectorModal,
    "payments-filter-menu": PaymentsFilterMenu,
    "primary-button": PrimaryButton,
    "export-results-modal": ExportResultsModal,
    "payment-details": PaymentDetails
  }
})
export default class PaymentActivity extends Vue {
  // reactive class properties

  private isLoadingTableData = false;
  private isLoadingTableFilters = false;
  private isColumnModalDisplayed = false;
  private searchFilter = "";
  private isExportInProgress = false;
  private isExportResultsModalVisible = false;
  private fileToBeDownloadedName = "";

  private readonly allCompanyOption: InputObject = {
    text: "All Companies",
    value: ""
  };
  private readonly allPaymentTypesOption: InputObject = {
    text: "All Payment Types",
    value: ""
  };
  private readonly allPaymentStatusesOption: InputObject = {
    text: "All Payment Statuses",
    value: ""
  };

  // readonly store modules

  private readonly paymentActivityStore: PaymentActivityStore = getModule(
    PaymentActivityStore,
    this.$store
  );

  private readonly userStore: UserStore = getModule(UserStore, this.$store);

  // computed properties

  private get customerID(): number | null {
    return this.paymentActivityStore.getCustomerID;
  }

  private get defaultHeaders(): TableColumnSelector[] {
    return this.paymentActivityStore.getDefaultHeaders;
  }

  private get availableHeaders(): TableColumnSelector[] {
    return this.paymentActivityStore.getAvailableHeaders;
  }

  private get headersToDisplay(): TableColumnSelector[] {
    return this.shouldShowPaymentDetails
      ? [
          ...this.availableHeaders.filter(column => column.selected),
          {
            align: "start",
            text: "Details",
            sortable: false,
            value: "payment_uuid",
            selected: false
          }
        ]
      : this.availableHeaders.filter(column => column.selected);
  }

  private get payments(): PaymentActivityRecord[] {
    return this.paymentActivityStore.getPayments;
  }
  private get selectedPayment(): PaymentActivityRecord | null {
    return this.paymentActivityStore.getSelectedPayment;
  }

  private get storedStartDate(): string {
    return this.paymentActivityStore.getStartDate;
  }
  private get storedEndDate(): string {
    return this.paymentActivityStore.getEndDate;
  }
  private get storedCompanyOptions(): InputObject[] {
    return this.paymentActivityStore.getCompanyOptions;
  }
  private get storedPaymentTypeOptions(): InputObject[] {
    return this.paymentActivityStore.getPaymentTypeOptions;
  }
  private get storedPaymentStatusOptions(): InputObject[] {
    return this.paymentActivityStore.getPaymentStatusOptions;
  }

  private get companyOptions(): InputObject[] {
    return [this.allCompanyOption, ...this.storedCompanyOptions];
  }
  private get selectedCompany(): string | null {
    return (
      this.paymentActivityStore.getSelectedCompany ??
      (this.allCompanyOption.value as string)
    );
  }
  private get paymentTypeOptions(): InputObject[] {
    return [this.allPaymentTypesOption, ...this.storedPaymentTypeOptions];
  }
  private get selectedPaymentType(): string | null {
    return (
      this.paymentActivityStore.getSelectedPaymentType ??
      (this.allPaymentTypesOption.value as string)
    );
  }
  private get paymentStatusOptions(): InputObject[] {
    return [this.allPaymentStatusesOption, ...this.storedPaymentStatusOptions];
  }
  private get selectedPaymentStatus(): string | null {
    return (
      this.paymentActivityStore.getSelectedPaymentStatus ??
      (this.allPaymentStatusesOption.value as string)
    );
  }

  private get paginationMetadata(): PaymentPaginationMetadata | null {
    return this.paymentActivityStore.getPaginationMetadata;
  }
  private get pagesLoaded(): number[] {
    return this.paymentActivityStore.getPagesLoaded;
  }
  private get currentPage(): number {
    return this.paginationMetadata?.page ?? 1;
  }
  private get itemsStart(): number {
    return (
      (this.paginationMetadata?.page ?? 1) *
        (this.paginationMetadata?.page_size ?? 0) -
      (this.paginationMetadata?.page_size ?? 0) +
      1
    );
  }
  private get totalPages(): number {
    return this.paginationMetadata?.total_pages ?? 0;
  }
  private get itemsStop(): number {
    if (
      this.totalItems < this.pageSize ||
      (this.paginationMetadata?.page ?? 1) *
        (this.paginationMetadata?.page_size ?? 0) >
        this.totalItems
    ) {
      return this.totalItems;
    } else {
      return (
        (this.paginationMetadata?.page ?? 1) *
        (this.paginationMetadata?.page_size ?? 0)
      );
    }
  }
  private get totalItems(): number {
    return this.paginationMetadata?.total_results ?? 0;
  }
  private get pageSize(): number {
    return this.paginationMetadata?.page_size ?? 10;
  }

  private get isExportButtonDisabled(): boolean {
    return this.payments.length == 0 || this.isExportInProgress;
  }

  private get shouldShowPaymentDetails(): boolean {
    return (
      this.$launchDarkly.variation("should-show-payment-details", false) ??
      false
    );
  }

  // lifecycle methods

  async created() {
    // check that current stored data is for current customer
    // this only applies to internal users that toggle between customers
    if (this.customerID != this.userStore.getSelectedCustomer?.id) {
      await this.paymentActivityStore.resetState();
    }
    this.paymentActivityStore.setCustomer(
      this.userStore.getSelectedCustomer?.id
    );
    // make API request to load filter menu options if no filters are stored in vuex
    if (
      this.storedCompanyOptions.length == 0 ||
      this.storedPaymentStatusOptions.length == 0 ||
      this.storedPaymentTypeOptions.length == 0
    ) {
      this.isLoadingTableFilters = true;
      const filters: PaymentActivityFiltersResponse = await paymentActivityService.getFilters();
      if (filters?.company_name) {
        this.paymentActivityStore.setCompanyOptions(filters.company_name);
      }
      if (filters?.payment_status) {
        this.paymentActivityStore.setPaymentStatusOptions(
          filters.payment_status
        );
      }
      if (filters?.payment_type) {
        this.paymentActivityStore.setPaymentTypeOptions(filters.payment_type);
      }
      this.isLoadingTableFilters = false;
    }

    if (this.payments.length == 0) {
      await this.loadTableData(
        new PaymentFilterMenuSelection(
          this.selectedCompany as string,
          this.selectedPaymentType as string,
          this.selectedPaymentStatus as string,
          this.storedStartDate,
          this.storedEndDate
        ),
        1
      );
    }
  }

  // methods

  handleTableColumnFiltersModal() {
    this.isColumnModalDisplayed = !this.isColumnModalDisplayed;
  }

  handleColumnUpdate(updatedColumns: TableColumnSelector[]) {
    this.paymentActivityStore.setAvailableHeaders(cloneDeep(updatedColumns));
    this.handleTableColumnFiltersModal();
  }

  async handleFilterChange(filters: PaymentFilterMenuSelection) {
    // store selected filters so that they remain set upon subsequent visits to this page
    this.paymentActivityStore.setSelectedCompany(filters.firstItemSelection);
    this.paymentActivityStore.setSelectedPaymentType(
      filters.secondItemSelection
    );
    this.paymentActivityStore.setSelectedPaymentStatus(
      filters.thirdItemSelection
    );
    this.paymentActivityStore.setStartDate(filters.startDate);
    this.paymentActivityStore.setEndDate(filters.endDate);

    // make API request for payment activity data related to selected filters
    await this.loadTableData(filters, 1);
  }

  async loadTableData(filters: PaymentFilterMenuSelection, page?: number) {
    // make API request for payment activity data related to selected filters
    this.isLoadingTableData = true;
    const payments = await paymentActivityService.get(filters, page);
    if (page && page > 1) {
      this.paymentActivityStore.setPayments([
        ...this.payments,
        ...payments.data
      ]);
      this.paymentActivityStore.setPagesLoaded([...this.pagesLoaded, page]);
    } else {
      this.paymentActivityStore.setPayments(payments.data);
      this.paymentActivityStore.setPagesLoaded([1]);
    }
    this.paymentActivityStore.setPaginationMetadata(payments.metadata);
    this.isLoadingTableData = false;
  }

  async handleNavigateToNextPage() {
    if (this.pagesLoaded.includes(this.currentPage + 1)) {
      this.paymentActivityStore.setPaginationMetadata({
        ...(this.paginationMetadata as PaymentPaginationMetadata),
        page: this.currentPage + 1
      });
    } else {
      await this.loadTableData(
        new PaymentFilterMenuSelection(
          this.selectedCompany as string,
          this.selectedPaymentType as string,
          this.selectedPaymentStatus as string,
          this.storedStartDate,
          this.storedEndDate
        ),
        this.currentPage + 1
      );
      this.paymentActivityStore.setPagesLoaded([
        ...this.pagesLoaded,
        this.currentPage
      ]);
    }
  }

  async handleNavigateToPreviousPage() {
    this.paymentActivityStore.setPaginationMetadata({
      ...(this.paginationMetadata as PaymentPaginationMetadata),
      page: this.currentPage - 1
    });
  }

  private setLocalDateTime(utcDate: string) {
    return utcDate ? utils.utcToLocalDateString(new Date(utcDate)) : "";
  }

  private setDateWithoutTime(utcDate: string) {
    return utcDate ? utils.formatStringToDateWithFormat(utcDate, "L") : "";
  }

  private formatMonetaryVal(value: number | string) {
    return formatMonetaryValue(value);
  }

  private async loadRemainingTableDataForExport(
    filters: PaymentFilterMenuSelection,
    page: number
  ) {
    // we don't update pagination metadata here so that the table remains on same page as when user clicked export
    const payments = await paymentActivityService.get(filters, page);
    this.paymentActivityStore.setPayments([...this.payments, ...payments.data]);
    this.paymentActivityStore.setPagesLoaded([...this.pagesLoaded, page]);
  }

  @Catch((error: any, context: any) => {
    context.fileToBeDownloadedName = "";
    context.handleExportResultsModal();
    context.isExportInProgress = false;
    context.$toasted.show(`<p>Error occurred</p>`, {
      ...options.ERROR_OPTIONS
    });
  })
  async handleExportResultsToCsv(fileName: string) {
    this.isExportInProgress = true;
    this.fileToBeDownloadedName = fileName;

    // load remaining pages' results
    const filters = new PaymentFilterMenuSelection(
      this.selectedCompany as string,
      this.selectedPaymentType as string,
      this.selectedPaymentStatus as string,
      this.storedStartDate,
      this.storedEndDate
    );

    const tasks: Promise<void>[] = [];
    for (let i = 1; i < this.totalPages + 1; i++) {
      if (!this.pagesLoaded.includes(i)) {
        tasks.push(this.loadRemainingTableDataForExport(filters, i));
      }
    }
    await Promise.all(tasks);

    downloadCsvFile(
      this.fileToBeDownloadedName,
      this.availableHeaders.filter(column => column.selected).map(h => h.text),
      this.availableHeaders.filter(column => column.selected).map(h => h.value),
      this.payments
    );

    this.fileToBeDownloadedName = "";
    this.handleExportResultsModal();
    this.isExportInProgress = false;
  }

  handleExportResultsModal() {
    this.isExportResultsModalVisible = !this.isExportResultsModalVisible;
  }

  navigateToPaymentDetail(payment: PaymentActivityRecord) {
    this.paymentActivityStore.setSelectedPayment(payment);
  }

  handleReturnToPayments() {
    this.paymentActivityStore.setSelectedPayment(null);
  }
}
